import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  InputGroup,
  FormControl,
  InputGroupText,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
  Label,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
// import { Tem } from "../../services/emaillogsservice";
import { TemplatenotificationService } from "../../services/templatenotificationService";
import { Getyearmonth } from "../../services/getyearmonth";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./template.css";
import Popupmodel from "./Popupmodel";


function Manageclient() {
  const [resultdata, setResultdata] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [modal, setModal] = useState(false);
  const [confirmtitle, setConfirmtitle] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [monthselected, setMonthselected] = useState("");
  const [yearselected, setYearselected] = useState("");
  const [loading,setLoading]=useState(false);
  const [disabledbtn,setDisabledbtn]=useState("");
  const [userid,setUserid]=useState("");
  const [vatMonth,setVatMonth]=useState("");
  const [status,setStatus]=useState(false);
  const [inputfields,setInputfields]=useState({id:null,status:null,columnname:null});

  
  //const [years,setYears]=useState(["Select Year","2022","2023","2024","2025"]);


  function get_staff() {
    TemplatenotificationService.getstaff("Manageclient").then(
      (response) => {
        if (response.status == 200) {
          const result = response.data.data;
          console.log(result);
          //setResultdata(result.emaillogs);
          const filteredItems = result.comapnylist.filter(
            (item) =>
              item.company_name &&
              item.company_name.toLowerCase().includes(filterText.toLowerCase())
          );
          setResultdata(filteredItems);
        }
      }
    );
  }
  const checked_status =(row)=>{
    if(row == '1'){
        return "checked"
    }else{
        return "";
    }
  }

  const checkdata= (id,company_name,status,columenames,page_name) =>{
   setInputfields({id:id,status:status,columnname:columenames})
    let status_name="";
    if(status){
      status_name="De-active";
    }else{
      status_name="Active";
    }
    toggle(id,company_name,status_name,columenames,page_name);
  }
  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
      wrap: true,
    },
    {
        
      name: "VAT  Status",
      selector: (row) =>
      (
    <label  className="switch">
      <input type="checkbox" checked={checked_status(row.vat_automail_status)} data={row.id} onClick={() => checkdata(row.id,row.company_name,checked_status(row.vat_automail_status),'vat_automail_status',"Vat Notification")}/>
      <span className="slider round"></span>
    </label>),
      wrap: true,
    },
    {
        name: "Year End Status",
        selector: (row) =>
        (<label  className="switch">
        <input type="checkbox" checked={checked_status(row.yeaend_automail_status)} data={row.id} onClick={() => checkdata(row.id,row.company_name,checked_status(row.yeaend_automail_status),'yeaend_automail_status',"Year End Notification")}/>
        <span className="slider round"></span>
      </label>),
        wrap: true,
      },
      {
        name: "Request For Information",
        selector: (row) =>
        (<label  className="switch">
        <input type="checkbox" checked={checked_status(row.request_data_automail_status)} data={row.id} onClick={() => checkdata(row.id,row.company_name,checked_status(row.request_data_automail_status),'request_data_automail_status',"Request For Information Notification")}/>
        <span className="slider round"></span>
      </label>),
        wrap: true,
      },
      {
        name: "Subscription Status",
        selector: (row) =>
        (<label  className="switch">
        <input type="checkbox" checked={checked_status(row.status)} data={row.id} onClick={() => checkdata(row.id,row.company_name,checked_status(row.status),'subscription_status',"Subscription Notification")}/>
        <span className="slider round"></span>
      </label>),
        wrap: true,
      },




  ];
  useEffect(() => {
    get_staff();
  }, [filterText,status]);

  const filter = (e) => {
    setFilterText(e);
  };


  const toggle = (id,company_name,status_name,columename,page_name) => {
    let body= "Do you want " +
    page_name +
        " " +
        status_name +
         " this company " +
         company_name;

    setConfirmtitle(body);
    setModal(!modal);
  };


  const sendmail = () => {
    console.warn(inputfields.id,inputfields.status,inputfields.columnname);
    toggle();
    setLoading(true);
        TemplatenotificationService.updatestatus(inputfields.id,inputfields.status,inputfields.columnname).
    then((response) => {
        console.log(response);
        if(response.status == 200) {
          return response.data;
        }
      }).then((data)=>{
        setStatus(true);
        setLoading(false);
        if(data.status=='success'){
          toast.success(data.data.msg);
        }else{
          toast.error(data.data.msg);
        }
        setStatus(false);
    }).catch((error)=>{
        setLoading(false);
      console.log(error);
      toast.error(error);
    })
  }



  return (
    
    <>

      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className="mt--7" fluid>

        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">

            <Card className="shadow">
              <CardHeader>
                <div className="justify-content-end row">
                  <Col xl="6" className="align-items-center">
                    <h2 className="header-title">Manage Client</h2>
                  </Col>
                  <Col xl="6" className="align-items-center">
                    <InputGroup>
                      <Input
                        onChange={(e) => filter(e.target.value)}
                        filterText={filterText}
                        placeholder="enter your search term ..."
                      />
                      <Button color="primary">Search</Button>
                    </InputGroup>
                  </Col>
                </div>
              </CardHeader>
              <CardBody>
              {loading ? <div className="loader"></div> : 
                <DataTable
                  columns={columns}
                  data={resultdata}
                  pagination
                  defaultSortField="subject"
                />
  }
               

              
                <CardFooter className="py-4"></CardFooter>
             
              </CardBody>
             
            </Card>
          </Col>
        </Row>
{/* start popup model*/}
<Popupmodel modal={modal} data={confirmtitle} toggle={(id='',company_name='',status='',columename='',page_name='')=> toggle()} functionname={sendmail}></Popupmodel>



      </Container>


    </>
  );
}

export default Manageclient;
